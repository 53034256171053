import Vue from "vue";
import Vuetify from "vuetify/lib";
import colors from "vuetify/lib/util/colors";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: true,
    themes: {
      dark: {
        primary: colors.orange.darken4,
        secondary: colors.grey.darken4,
        white: colors.grey.lighten5,
        accent: colors.grey.darken3,
        error: "#b71c1c",
      },
    },
    options: {
      customProperties: true,
    },
    fontFamily: "Jost",
  },
});
