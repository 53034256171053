<template>
  <v-app class="white--text">
    <v-row class="flex justify-center ma-0">
      <v-col cols="12" md="10" lg="8" xl="8">
        <core-app-bar />

        <main>
          <core-view />
        </main>

        <core-footer />
      </v-col>
    </v-row>
  </v-app>
</template>

<script>
export default {
  name: "App",

  components: {
    CoreAppBar: () => import("@/components/core/AppBar"),
    CoreFooter: () => import("@/components/core/Footer"),
    CoreView: () => import("@/components/core/View"),
  },
};
</script>

<style>
@import "~animate.css/animate.min.css";
@import url("https://fonts.googleapis.com/css2?family=Bad+Script&display=swap");

html,
body,
#app {
  background-color: #212121;
}

.custom-font-fam {
  font-family: "Bad Script", cursive !important;
}

#welcome {
  min-height: 100vh;
}


#welcome {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

#education {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.text-overline {
  line-height: 1rem;
}

.custom-cursor {
  cursor: pointer;
}
</style>
