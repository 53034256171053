<template>
  <component :is="tag" :class="`${color}--text ${custom_class} v-heading`" class="mb-3 font-weight-medium">
    <slot />
  </component>
</template>

<script>
export default {
  name: "BaseHeading",

  props: {
    tag: {
      type: String,
      default: "h1",
    },
    custom_class: {
      type: String,
      default: "display-1",
    },
    color: String,
  },
};
</script>

<style>
.v-heading {
  letter-spacing: 3px !important;
}
</style>
