import Vue from "vue";
import "./plugins/base";
import vuetify from "./plugins/vuetify";
import App from "./App.vue";
import AOS from "aos";
import "aos/dist/aos.css";

Vue.config.productionTip = false;

new Vue({
  vuetify,
  mounted() {
    AOS.init();
  },
  render: (h) => h(App),
}).$mount("#app");
